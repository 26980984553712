$input-group-addon-bg: $body-bg;
$input-group-addon-color: $body-color;

.st-datepicker {
    width: 100%;

    .react-datepicker {
        background-color: shade-color($body-bg, 2%);
        border-color: $input-border-color;
        color: $body-color;
        z-index: 99999999;
        // font-family: $font-family-sans-serif;

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view--down-arrow {
            top: 3px;
        }

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view--down-arrow,
        .react-datepicker__navigation-icon::before {
            border-width: 2px 2px 0 0;
            border-color: shade-color($body-color, 20%);
            width: 8px;
            height: 8px;
        }

        .react-datepicker__navigation:hover ::before,
        .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow {
            border-color: shade-color($body-color, 1%);
        }

        .react-datepicker__header {
            background-color: shade-color($body-bg, 7%);
            border-color: $input-border-color;

            .react-datepicker__current-month,
            .react-datepicker-time__header,
            .react-datepicker-year-header {
                color: $body-color;
            }

            .react-datepicker__year-dropdown,
            .react-datepicker__month-dropdown,
            .react-datepicker__month-year-dropdown {
                background-color: shade-color($body-bg, 7%);
                border-color: $input-border-color;
                border-radius: 0;
            }

            .react-datepicker__year-option:hover,
            .react-datepicker__month-option:hover,
            .react-datepicker__month-year-option:hover {
                background-color: shade-color($body-bg, 15%);
            }
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            color: $body-color;
            line-height: 1.3rem;
            padding-top: 3px;
        }

        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__year-text:hover {
            border-radius: 0rem;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
            border-radius: 0rem;
            background-color: var(--bs-primary);
            color: $white;
            line-height: 1.3rem;
            padding-top: 3px;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range,
        .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range),
        .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range),
        .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range),
        .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range),
        .react-datepicker__day--selected:hover,
        .react-datepicker__day--in-selecting-range:hover,
        .react-datepicker__day--in-range:hover,
        .react-datepicker__month-text--selected:hover,
        .react-datepicker__month-text--in-selecting-range:hover,
        .react-datepicker__month-text--in-range:hover,
        .react-datepicker__quarter-text--selected:hover,
        .react-datepicker__quarter-text--in-selecting-range:hover,
        .react-datepicker__quarter-text--in-range:hover,
        .react-datepicker__year-text--selected:hover,
        .react-datepicker__year-text--in-selecting-range:hover,
        .react-datepicker__year-text--in-range:hover {
        
            background-color: var(--bs-primary) !important;
            color: $white;
            border-radius: 0;
        }
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker {
        box-shadow: 0 -6px 12px -9px $body-color;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker {
        box-shadow: 0 6px 12px -9px $body-color;
    }

    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
        border-bottom-color: $input-border-color;
    }
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
        border-top-color: shade-color($body-bg, 2%);
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: $input-border-color;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
        border-bottom-color: shade-color($body-bg, 7%);
    }

}