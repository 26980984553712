$primary: #28a852;
$darkprime: #2c945d;
$secondary: #6d6e72;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": #28a852,
    "info": #00bfc0,
    "warning": #dead26,
    "danger": #f78989,
    "light": #fafafa,
);

$custom-colors: (
    "darkprime": #2c945d
);
$theme-colors: map-merge($theme-colors, $custom-colors);


// dark night variables
// $body-bg: #222;
// $body-color: #999;

// Others
$nav-link-height: 2.8rem;
$nav-top-height: 3.2rem;
$table-border-color: rgba(145, 145, 145, 0.2);
$enable-rounded: false;
$font-family-sans-serif: "Open Sans", sans sarif;
$font-size-root: "15px";
$input-border-color: rgba(145, 145, 145, 0.2);