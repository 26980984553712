.st-select {
    background-image: none !important;
    padding: 0 !important;

    .multiselectInput .css-yk16xz-control,
    .multiselectInput .css-1pahdxg-control,
    .react-select-tags .css-yk16xz-control,
    .react-select-tags .css-1pahdxg-control,
    .css-1s2u09g-control,
    .css-1pahdxg-control,
    .css-yk16xz-control {
        background-color: $body-bg !important;
        border-radius: 0 !important;
        color: $body-color !important;
        outline: none !important;
        min-height: auto !important;
    }

    .css-1wa3eu0-placeholder,
    .css-1insrsq-control,
    .css-1pgjs0x-placeholder,
    .css-14el2xx-placeholder {
        font-size: 1rem !important;
        font-family: $font-family-sans-serif !important;
        color: $input-placeholder-color;
        opacity: 0.5 !important;
    }

    .css-1uccc91-singleValue,
    .css-qc6sy-singleValue {
        font-size: 1rem !important;
        font-family: $font-family-sans-serif !important;
        color: var(--bs-body-color);
    }

    .css-1insrsq-control {
        border: $input-border-width solid $input-border-color !important;
        background-clip: padding-box;
        background-color: shade-color($body-bg, 7%) !important;
        border-radius: 0 !important;
        outline: none !important;
        min-height: auto !important;

        .css-lr9fot-singleValue {
            color: shade-color($body-color, 40%) !important;
        }

        .css-109onse-indicatorSeparator {
            background-color: $input-border-color !important;
        }
    }

    .css-1s2u09g-control {
        border: $input-border-width solid $input-border-color !important;
        background-clip: padding-box;
    }

    .css-1pahdxg-control {
        box-shadow: $input-btn-focus-box-shadow;
        border: $input-border-width solid $input-focus-border-color !important;
        outline: 0 !important;
        background-clip: initial;
    }

    .css-tj5bde-Svg {
        fill: $body-color !important;
        height: 18px !important;
    }

    .css-1okebmr-indicatorSeparator {
        background-color: $input-border-color !important;
    }

    .css-26l3qy-menu {
        background-color: $body-bg !important;
        border: $input-border-width solid $input-border-color !important;
        z-index: 999;

        .css-9gakcf-option {
            background-color: var(--bs-primary) !important;
        }

        .css-1n7v3ny-option {
            background-color: shade-color($body-bg, 15%) !important;
            color: shade-color($body-color, 14%) !important;
        }
    }
}