@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/open-sans-v34-latin-300.eot');
  src: local(''),
    url('assets/fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/open-sans-v34-latin-300.woff2') format('woff2'),
    url('assets/fonts/open-sans-v34-latin-300.woff') format('woff'),
    url('assets/fonts/open-sans-v34-latin-300.ttf') format('truetype'),
    url('assets/fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/open-sans-v34-latin-regular.eot');
  src: local(''),
    url('assets/fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
    url('assets/fonts/open-sans-v34-latin-regular.woff') format('woff'),
    url('assets/fonts/open-sans-v34-latin-regular.ttf') format('truetype'),
    url('assets/fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/open-sans-v34-latin-700.eot');
  src: local(''),
    url('assets/fonts/open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/open-sans-v34-latin-700.woff2') format('woff2'),
    url('assets/fonts/open-sans-v34-latin-700.woff') format('woff'),
    url('assets/fonts/open-sans-v34-latin-700.ttf') format('truetype'),
    url('assets/fonts/open-sans-v34-latin-700.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/open-sans-v34-latin-300italic.eot');
  src: local(''),
    url('assets/fonts/open-sans-v34-latin-300italic.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/open-sans-v34-latin-300italic.woff2') format('woff2'),
    url('assets/fonts/open-sans-v34-latin-300italic.woff') format('woff'),
    url('assets/fonts/open-sans-v34-latin-300italic.ttf') format('truetype'),
    url('assets/fonts/open-sans-v34-latin-300italic.svg#OpenSans') format('svg');
}

#loader {
  background: rgba(0, 0, 0, 0.6);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show{
  color: white;
}

.user-feed{

  font-size: 0.8rem;
}
.user-feed .material-symbols-sharp {
 font-size: 19px;
}
.reply-box {
  height: 50px;
}