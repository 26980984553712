// Configuration (bootstrap)
@import "../../../node_modules/bootstrap/scss/functions";

// Custom variables
@import '../sass/abstracts/';

@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/utilities";

// Custom Components
@import '../sass/components/progress';
@import '../sass/components/react-datepicker';
@import '../sass/components/react-select';
@import '../sass/components/login';
@import '../sass/components/tab';
@import '../sass/components/navbar';
@import '../sass/components/table';
@import '../sass/components/loader';
@import '../sass/components/others';

@import "../../../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("../../../node_modules/bootstrap/scss/utilities/");

// Layout & components

@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
//@import "../../../node_modules/bootstrap/scss/card";
// @import "../../../node_modules/bootstrap/scss/accordion";
// @import "../../../node_modules/bootstrap/scss/breadcrumb";
// @import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
//@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
//@import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
// @import "../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../node_modules/bootstrap/scss/popover";
// @import "../../../node_modules/bootstrap/scss/carousel";
// @import "../../../node_modules/bootstrap/scss/spinners";
//@import "../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../node_modules/bootstrap/scss/placeholders";
@import "../../../node_modules/bootstrap/scss/helpers";
@import "../../../node_modules/bootstrap/scss/utilities/api";

:root[data-theme='dark'] {


    $theme-colors-rgb: ("body-color": #FFF,
        "body-bg": #000000,
    );
    $theme-colors: ("body-color": #FFF,
        "body-bg": #000000,
    );

    @each $color,
    $value in $theme-colors {
        --#{$prefix}#{$color}: #{$value};
    }

    @each $color,
    $value in $theme-colors-rgb {
        --#{$prefix}#{$color}-rgb: #{$value};
    }

    --#{$prefix}body-color-rgb: #{to-rgb($body-color)};
    --#{$prefix}body-bg-rgb: #{to-rgb($body-bg)};
    --#{$prefix}body-bg: #{$body-bg};
    --#{$prefix}body-color: #{$body-color};
}