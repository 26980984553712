.st-tablist {
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;

    .react-tabs__tab {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding: 10px 10px;
        text-transform: uppercase;
        text-align: center;
        color: $body-color;
        flex: 1;
        min-height: $nav-link-height;
        cursor: $form-range-track-cursor;
        transition: $transition-base;

        &:not(.react-tabs__tab--selected):hover {
            background-color: inherit;
            color: $body-color;

        }

        h5 {
            font-size: 1.07em;
            margin-bottom: 0;
            font-weight: bold;
        }

        .slide {
            width: 0px;
            position: absolute;
            left: auto;
            top: calc(100% - 0px);
        }

        &:first-of-type {
            &.react-tabs__tab--selected {
                ~.slide {
                    left: 0;
                }
            }
        }

    }

    .react-tabs__tab--selected {
        font-weight: 700;
        outline: none;
        color: $body-color;

        .slide {
            background: var(--bs-primary);
            width: 90%;
            height: 2px;
            position: absolute;
            left: auto;
            top: calc(100% - 0px);
            -webkit-transition: width .2s ease-in-out;
            -moz-transition: width .2s ease-in-out;
            -o-transition: width .2s ease-in-out;
            transition: width .2s ease-in-out;
        }
    }


}

@include media-breakpoint-down(lg) {
    .st-tablist {
      flex-direction: row !important;
      flex-wrap: wrap;

      li {
        flex: 1 auto !important;
      }
    }
  }

.react-tabs__tab-panel:first-of-type {
    border-top: 2px solid $table-border-color;
}