.progress {

    .progress-indicator {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin: 0 0 1em;
        padding: 0;
        font-size: 80%;

        li {
            -ms-flex: 1;
            -webkit-flex: 1;
            -moz-flex: 1;
            flex: 1;
            list-style: none;
            text-align: center;
            width: auto;
            padding: 0;
            margin: 0;
            position: relative;
            text-overflow: ellipsis;
            color: #bbb;
            display: block;

            &:first-child {

                .bubble {

                    &::before,
                    &::after {
                        width: 50%;
                        margin-left: 50%
                    }
                }

            }

            &:last-child {
                .bubble {

                    &::before,
                    &::after {
                        width: 50%;
                        margin-right: 50%
                    }
                }
            }

            &.danger {
                color: #d3140f;

                .bubble {
                    color: #d3140f;
                    background-color: #d3140f;
                    border-color: #440605;

                    &::after,
                    &::before {
                        background-color: #d3140f;
                        border-color: #440605;
                    }


                }
            }

            &.completed {
                color: #65d074;

                .bubble {
                    color: #65d074;
                    background-color: #65d074;
                    border-color: #247830;

                    &::after,
                    &::before {
                        background-color: #65d074;
                        border-color: #247830;
                    }


                }
            }

            &.active {
                color: #edb10a;

                .bubble {
                    color: #edb10a;
                    background-color: #edb10a;
                    border-color: #5a4304;

                    &::after,
                    &::before {
                        background-color: #edb10a;
                        border-color: #5a4304;
                    }


                }
            }

            &:hover {
                color: #6f6f6f
            }

            .bubble {
                width: 30px;
                height: 30px;
                background-color: #bbb;
                display: block;
                margin: 0 auto .5em;

                &::after,
                &::before {
                    display: block;
                    position: absolute;
                    top: 15px;
                    width: 100%;
                    height: 3px;
                    content: '';
                    background-color: #bbb
                }

                &::before {
                    left: 0
                }

                &::after {
                    right: 0
                }
            }

            i {
                position: absolute;
                left: 50%;
                margin-left: -10px;
                color: white;
                z-index: 99;
                font-size: 20px;
                top: 5px;
            }

        }

    }
}

@media handheld,
screen and (max-width:400px) {
    .progress-indicator {
        font-size: 60%
    }
}

.comments {

    .comment-box {
        height: 80px;
    }

    .comms-date {
        font-size: 90%;
        opacity: 0.6;
    }
}

.section-title {
    border-bottom: 0.17rem solid $input-border-color;
    padding-bottom: 0.65rem;
}

.st-comments {
    &:not(:first-child) {
        border-top: 1px solid $input-border-color;
    }
}