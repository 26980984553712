.topnav {
    padding: 0 !important;
    z-index: $zindex-toast !important;
    background-color: $body-bg;
    color: $body-color;
    height: $nav-top-height !important;

    .active {
        background-color: $darkprime;
        color: #ffffff !important;
    }

    .navbar-brand {
        display: flex;
        height: calc($nav-link-height + 0.1rem);
        padding-left: 0.75rem;

        .logo {
            width: 100%;
            height: 100%;
        }
    }

    .navbar-toggler-container {
        display: flex;
        height: 100%;
        align-items: center;
        padding-right: 0.75rem;
    }

    @include media-breakpoint-down(lg) {
        .navbar-collapse.show {
            background-color: $body-bg;
            width: calc(100% + 0.5rem);
            box-shadow: $box-shadow;
            padding: 0.75rem;
        }
    }

    .navbar-nav {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        .nav-user {
            display: flex !important;
            align-items: center;
            justify-content: center;

            .nav-link {
                color: $body-color;
            }
        }

        .nav-item {
            padding: 0 !important;
            @include media-breakpoint-down(lg) {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .nav-link {
                height: $nav-top-height !important;
                width: calc($nav-top-height + 2px);
                padding: 0;
                display: flex !important;
                align-items: center;
                transition: $nav-link-transition;
                color: $body-color;
                justify-content: center;

                @include media-breakpoint-up(lg) {
                    &:hover {
                        background-color: var(--bs-secondary);
                        color: $light;
                    }
                }
            }
        }
    }
}

.subnav {
    top: $nav-top-height !important;
    padding: 0 !important;

    .active {
        background-color: $darkprime;
    }
    .nav-item:first-child {
        .nav-link {
            padding-left: 0.6rem !important;
            white-space: nowrap;
        }
    }
    .nav-link {
        height: $nav-link-height;
        padding: 0 1.5rem !important;
        display: flex !important;
        justify-content: center;
        align-items: center;

        &:hover {

            background-color: $secondary;
        }

        .material-symbols-sharp {
            margin-right: 0.1rem;
        }
    }

    .dropdown-item {
        padding: 0.5rem 1.95rem 0.5rem 1.55rem !important;
        display: flex !important;
        align-items: center;
        min-width: 12.3rem !important;
    }
}

.sub-dropdown {
    display: none;
}

.dropdown-menu .dropdown-menu {
    left: 100%;
    margin-top: -3.15rem;
}