.login-section {
	background: $body-bg;
	position: absolute;
	height: 100vh;
	width: 100%;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);
	overflow: hidden;
	top: 0;
	z-index: 99999999;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: flex-start;

	.login-wrap {
		position: relative;
		max-width: 500px;
		background: $body-bg;
		border-radius: 10px;
		border: 1px solid $input-border-color;

		h3 {
			font-weight: 700;
			font-size: 20px;
			color: var(--bs-primary);
		}

		.icon {
			width: 80px;
			height: 80px;
			background: var(--bs-primary);
			border-radius: 50%;
			font-size: 30px;
			margin: 0 auto;
			margin-bottom: 10px;

			svg {
				color: $white;
			}
		}
	}
}

.checkbox-wrap {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox-wrap input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
}

.checkmark:after {
	content: "\f0c8";
	font-family: "FontAwesome";
	position: absolute;
	color: rgba(0, 0, 0, .1);
	font-size: 20px;
	margin-top: -4px;
	@include transition(.3s);
}

.checkbox-wrap {
	input:checked~.checkmark:after {
		display: block;
		content: "\f14a";
		font-family: "FontAwesome";
		color: rgba(0, 0, 0, .2);
	}
}

.checkbox-primary {
	color: var(--bs-primary);

	input:checked~.checkmark:after {
		color: var(--bs-primary);
	}
}