.pageOverlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999999;
    display: block;
    text-align: center;

    .loader-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

        .loader-logo {
            position: absolute;
            animation: loader-logo-shrink 2s ease infinite;
        }
    }

    .loader {
        position: relative;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background: linear-gradient($darkprime, $light, var(--bs-primary));
        animation: animate 1.2s linear infinite;
    }

    @keyframes animate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .loader span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: linear-gradient($secondary, $secondary, var(--bs-primary));
    }

    // .loader span:nth-child(1) {
    //     filter: blur(1px);
    // }

    // .loader span:nth-child(2) {
    //     filter: blur(5px);
    // }

    // .loader span:nth-child(3) {
    //     filter: blur(15px);
    // }

    // .loader span:nth-child(4) {
    //     filter: blur(30px);
    // }


    @keyframes loader-logo-shrink {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }

    .loader:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        background: #f1f1f1;
        border: solid $secondary 3px;
        border-radius: 50%;
    }
}