@include media-breakpoint-down(md) {
    .table-flexible {

        .thead,
        tbody {
            display: block !important;
            width: 100%;

            tr {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                border-bottom: 1px solid $table-border-color;


                td {
                    flex-grow: 1;
                    border: none;
                }

                td:last-child {

                    &:has(span) {
                        align-self: center;
                        justify-content: flex-end;
                        display: flex;
                    }
                }
            }
        }
    }
}

.table-flexible {
    tr {
        .badge {
            width: 100%;
            max-width: 120px;
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-weight: normal;
            margin-top: .5rem;
        }

        td {
            h6 {
                &:hover {
                    text-decoration: underline;
                }
            }

            &:last-child {
                vertical-align: middle;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .po-order {
        width: 12%;
    }

    .po-created {
        width: 12%;
    }

    .po-contracter {
        width: 20%;
    }

    .po-care {
        width: 20%;
    }

    .po-service {
        width: 12%;
    }

    .po-price {
        width: 8%;
    }

    .po-vat {
        width: 8%;
    }

    .po-net {
        width: 8%;
    }
}

.po-table {
    th {
        vertical-align: top;
    }

    .border-t0 {
        border-top: 1px solid transparent !important;
    }

    .border-b0 {
        border: 1px solid $table-border-color !important;
    }
}
.thead {
    border-bottom: 2px solid shade-color($input-border-color, 6%);
}