.blank-div {
    background-color: shade-color($body-bg, 3%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-400;
    font-size: 1.5em;
    min-height: 6rem;
}

.clickable {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px $body-bg inset !important;
    box-shadow: 0 0 0px 1000px $body-bg inset !important;
}

.chc-product {
    font-size: 1.2em;
    font-weight: 100;
    padding-top: 2px;
    border-left: 1px solid $input-border-color;
    padding-left: 15px;
}

footer {
    height: $nav-link-height;
}

.main {
    padding-top: $nav-link-height + $nav-top-height;
}

.st-filter {
    top: $nav-link-height + $nav-top-height !important;
    background-color: shade-color($body-bg, 6%);

    .form-label small {
        font-size: 0.7rem;
        font-weight: 600;
        letter-spacing: 0.02rem;
        padding-left: 0.2rem;
        color: $body-color;
        opacity: 0.3;
    }
}

.content {
    min-height: calc(100vh - $nav-top-height - $nav-link-height * 2);
}

.material-symbols-sharp {
    font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
}

.grid-head {
    background-color: $darkprime;
    color: $light;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

    h4 {
        margin: 0 !important;
        font-size: 1.25rem !important;
    }
}

.btn-outline-success {
    --bs-btn-hover-color: var(--bs-white) !important;
    --bs-btn-active-color: var(--bs-white) !important;
}

.dropdown-toggle-right::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0 solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
}

.flip-180 {
    transform: rotateX(180deg);
}

.btn-min {
    min-width: 115px;
}

.form-control:disabled {
    background-color: shade-color($body-bg, 7%) !important;
}

.form-check-input {
    width: 1.3em !important;
    height: 1.3em !important;
}

small.text-muted {
    font-size: 0.7rem;
    color: $body-color;
    opacity: 0.6;
}

.text-darkprime {
    color: $darkprime;
}

.input-group-text {
    background-color: shade-color($body-bg, 7%) !important;
    span {
        opacity: 0.4 !important;
    }
}